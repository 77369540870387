import React from 'react';
import './TopRow.css';
import uscLogo from './usc_logo_black.png'; // Import the USC logo

function TopRow({ onToggleDocumentation, showDocumentation}) {
  return (
    <div className="top-row">
      <h2><a href="https://rohslab.usc.edu/deeppbs/#" className="main">DeepPBS</a></h2>
      <div className="buttons-and-logo">
        <div className="button-container">
          {/* <button id="run-on-ex-data" className="toggle-docs-btn" type="button">
            Link to Code Ocean
          </button> */}
          <button className="toggle-docs-btn" id="toggle-docs-btn-id" onClick={onToggleDocumentation}>
            {showDocumentation ? "Hide FAQ" : "Show FAQ"}
          </button>
        </div>
        <a href="https://www.usc.edu/" className="usc-logo-link">
          <img src={uscLogo} alt="USC Logo" className="usc-logo" />
        </a>
      </div>
    </div>
  );
}

export default TopRow;
