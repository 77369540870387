import React from 'react';
import './Faq.css';

const faqData = [
  {
    question: "What is DeepPBS?",
    answer: (
      <>
        DeepPBS is a geometric deep learning model for predicting binding specificity based on a given protein-DNA co-complex structure. You can read the paper <a href="https://doi.org/10.1038/s41592-024-02372-w">here</a>.
      </>
    ),
  },
  {
    question: "What are the input format requirements?",
    answer: (
      <>
        The input should be a PDB format file reflecting a biological assembly containing one or more proteins bound to <u>one contiguous DNA helix</u> (example: PDB ID: 8OJ1, biological assembly 1). mmCIF format files should also work, but they are not extensively tested. Residue names and atom names should adhere to standard PDB style (e.g., DA for adenine, DC for cytosine, OP1 for phosphate oxygen). Please make sure no nucleotide is missing the following atoms: C1', C4', C5', N1, N9. Please remove or replace modified nucleotides and ensure there are no broken backbone linkages in the input structure.
      </>
    ),
  },

  {
    question: "How do I use DeepPBS?",
    answer: "To use DeepPBS, upload your protein-DNA complex structure file, select the desired options, and click 'Run DeepPBS'. Results will be displayed upon completion of the analysis.",
  },

  {
    question: "What are the different model types?",
    answer: (
      <>
      DeepPBS is trained to predict specificity based on either "groove readout", "shape readout" or "both readout" of the sym-Helix by the proteins. Additionally, the DNA sequence information ("with DNASeqInfo") in the structure can be used too (not recommended). These make up the four different model types, and they can be selected in the "Model Selection" field on the homepage. For more information please see the paper <a href="https://www.biorxiv.org/content/10.1101/2023.12.15.571942v1">here</a>.
      </>
    ),
  },

  {
    question: "What are the heavy atom level relative importance (RI) scores?",
    answer: "The heavy atom level RI scores are the network's estimation of how important different protein heavy atoms (in the binding interface) are for determining its output. This is computed via perturbation and hence takes longer than simple prediction.",
  },

  {
    question: "What are the output files?",
    answer: (
      <ol>
        <li>[JOB].log: Log file of job processing.</li>
        <li>[JOB].npz_ensemble.svg: Position Weight Matrix.</li>
        <li>[JOB].npz_predict.npz: Position Weight Matrix in numerical format.</li>
        <li>[JOB]_processed_input.npz: Processed structure in numerical format used as input for DeepPBS.</li>
        <li>(RI only) [JOB]_pymol_session.pse: An interactive PyMOL session allowing you to visualize and explore heavy atom RI.</li>
        <li>(RI only) [JOB]_pymol_session.png: An example image from the PyMOL session, with RI proportional to the size of heavy atom spheres.</li>
        <li>(RI only) Intermediate files for heavy atom RI (refer to <a href="https://github.com/timkartar/DeepPBS/blob/main/run/plot_scripts/vis_interpret.py">GitHub vis_interpret.py</a> for usage):
          <ul>
          <li>(RI only) [JOB].npz_diffs.npy</li>
          <li>(RI only) [JOB].npz_edge_index.npy</li>
          <li>(RI only) [JOB].npz_v_prot.npy</li>
          <li>(RI only) [JOB].npz_xprot.npy</li>
          </ul>
        </li>
      </ol>
    ),
  },

  {
    question: "I have hundreds of structures to run DeepPBS on. Can I use this web server?",
    answer: (
      <>
      The webserver is CPU only and is meant to provide quick and easy access to first time users wishing to explore DeepPBS. If you have a lot of jobs to run, we recommend utilizing the <a href="https://doi.org/10.24433/CO.0545023.v2">Code Ocean</a> option (copy into your own capsule) or setting up locally (see <a href="https://github.com/timkartar/DeepPBS">GitHub</a>). If you are having difficulties setting up locally please feel free to raise an issue via <a href="https://github.com/timkartar/DeepPBS/issues">GitHub</a>.
      </>
    ),
  },

  {
    question: "My job resulted in an error. How can I troubleshoot?",
    answer: (
      <>
      The result page will show a brief log of your job's processing. This may give you some hints on what went wrong. If you are still having trouble, please explore the existing <u><a href="https://github.com/timkartar/DeepPBS/issues">GitHub</a> issues or create a new one.</u>
      </>
    ),
  },
];

const Faq = () => {
  return (
    <div className="faq-wrapper">
      <div className="faq-content">
        <h1>Frequently Asked Questions</h1>
        {faqData.map((faq, index) => (
          <div key={index} className="faq-item">
            <h2>{faq.question}</h2>
            <div>{faq.answer}</div>  {/* Render the answer, which may now include JSX */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Faq;
