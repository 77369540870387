import logo from './logo.svg';
import './App.css';
import axios from 'axios';
import TopRow from './TopRow';
import './TopRow.css';
import SplashScreen from './SplashScreen';
import React, {useState, useRef, useEffect} from 'react';
import Modal from './Modal.js'
import './Modal.css'
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import NGLViewer from './NGLViewer';
import ImageRow from './ImageRow.js';
import Faq from './Faq.js'
import './ImageRow.css'

function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

function App() {
  // const baseUrl = 'http://10.136.114.14'
  const baseUrl = 'https://deeppbs.usc.edu'

  const [file, setFile] = useState(null);
  const [calcRI, setCalcRI] = useState(false);
  const [model, setModel] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [modelText, setModelText] = useState("");
  const [logtext, setLogtext] = useState("");
  const [ipMsg, setIpMsg] = useState("");

  const[isExample, setIsExample] = useState(true);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [modalLink, setModalLink] = useState('');

  const [jobNotComplete, setJobNotComplete] = useState(false);

  const [timeString, setTimeString] = useState('');
  const [triggerSubmit, setTriggerSubmit] = useState(false); //trigger getting the information for someone

  const [showSplash, setShowSplash] = useState(true); // State to control SplashScreen
  const [imageUrl, setImageUrl] = useState(false);
  const [zipUrl, setZipUrl] = useState(false);
  const [title, setTitle] = useState("");

  const [pymolUrl, setPymolUrl] = useState(false);
  const [pymolPseUrl, setPymolPseUrl] = useState(false);

  const [showDocumentation, setShowDocumentation] = useState(false);

  const toggleDocumentation = () => {
    setShowDocumentation(!showDocumentation);
  };

  // Function to open modal with a custom message and link
  const openModal = (message, link) => {
    setModalMessage(message);
    setModalLink(link);
    setIsModalOpen(true);
  };

  let location = useLocation();
  // Function to handle the path
  const handlePath = (path) => {
    console.log('Current Path:', path);
    let splitPath = path.split('/');
    console.log(splitPath);
    
    if (splitPath.length > 2) {
      setIsExample(false);
      setTimeString(splitPath[2]);
      setTriggerSubmit(true);
    }
  };

  // submit the time string if path changes
  useEffect(() => {
    if (timeString !== "" && triggerSubmit) {
      setShowSplash(false);
      handleGetOutput();
      setTriggerSubmit(false); // Reset back to false after handling submit

    const message = sessionStorage.getItem('modalMessage');
    const link = sessionStorage.getItem('modalLink');

    if (message && link) {
      openModal(message, link);
      // Optionally clear session storage if it's no longer needed
      sessionStorage.removeItem('modalMessage');
      sessionStorage.removeItem('modalLink');
    }
    }
  }, [timeString, triggerSubmit]); // Listen to changes in both pdbid and triggerSubmit

  // monitor when the path changes. if it does, call handle path
  useEffect(() => {
    // Extract the path from the location
    const path = location.pathname;
    handlePath(path);
  }, [location]); // Re-run this effect if the location changes

  // enable downloading zip
  function downloadFile(fileUrl) {
    // Create a new anchor element dynamically
    const anchor = document.createElement('a');
    
    // Set the href to the URL pointing to your Django server's media file
    anchor.href = fileUrl;
    
    // Optional: Set the download attribute to a default filename you want to use
    // anchor.download = "downloadedFile.svg";
  
    // Append the anchor to the body (required for Firefox)
    document.body.appendChild(anchor);
    
    // Simulate a click on the anchor
    anchor.click();
    
    // Remove the anchor from the body once the download is initiated
    document.body.removeChild(anchor);
  }


  // upload a file
  function handleSubmit(event) {
    event.preventDefault();
    const url = baseUrl + '/deeppbs-backend/deeppbs-backend/run-deeppbs/';
    setIsLoading(true); // Start loading

    if (!file) {
      alert('Please upload a file.');
      setIsLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    if(calcRI){
      formData.append('calcRI', "1");
    }else{
      formData.append('calcRI', "0");
    }
    formData.append('model', model);


    axios.post(url, formData, {
      headers: {
        'content-type': 'multipart/form-data',
        'X-CSRFToken': getCookie('csrftoken'),
      },
      withCredentials: true,
    }).then(response => {
      // Check if structure is too big, and a link is returned instead
      if(response.data.time_string){
        const redirectUrl = `${baseUrl}/link/${response.data.time_string}`;

        sessionStorage.setItem('modalMessage', "Your structure has been queued for processing and will be available shortly. Please save or bookmark the below link. Access will expire after 30 days.");
        sessionStorage.setItem('modalLink', redirectUrl);
    
        // Redirect to the new URL
        window.location.href = redirectUrl;
      } else{
        alert("Error submitting a job.");
      }
    }).catch(error => {
    if (error.response && error.response.data && error.response.data.error) {
      alert(`Error: ${error.response.data.error}`);
    } else {
        alert('Error loading file! This is most commonly caused by a structure file that is too large or formatted improperly.');
    }
    }).finally(() => {
      setIsLoading(false); // Stop loading
    });
  }

// Send timeString and rotation via axios get request to run_regen_labels
function handleGetOutput(event) {
  // Define the URL for the GET request
  const url = baseUrl + `/deeppbs-backend/deeppbs-backend/run-get-output/`;
  // Set up the query parameters
  const params = {
    timeString: timeString,
  };
  setIsLoading(true); // Start loading
  return axios.get(url, { params })
    .then(response => {
      setImageUrl(response.data.svg);
      setZipUrl(response.data.zip);
      setTitle(response.data.title);
      setModelText(response.data.model);
      if (!(response.data.pymol_svg_url.trim() === "none")){
        setPymolUrl(response.data.pymol_svg_url)
      }
      if (!(response.data.pymol_pse_url.trim() === "none")){
        setPymolPseUrl(response.data.pymol_pse_url)
      }
      // something with response.data.zip
      return response.data.svg;
      // You might want to update some state here based on the response
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.error) {
        setJobNotComplete(true);    
        setIpMsg(error.response.data.error.trim());
        setLogtext(error.response.data.logtext.trim());
        setModelText(error.response.data.model.trim());
        setTitle(error.response.data.title.trim());
      }
      else {
          openModal("Error getting your job's output!", "");
          throw error;
      }
    })
    .finally(() => {
      setIsLoading(false); // Start loading
    });
}

  function handleChange(event) {
    setFile(event.target.files[0]);
  }
  
  const handleCheckboxChange = (event) => {
    setCalcRI(event.target.checked);
  };

  
  return (
    <div className="App">
      {showSplash && <SplashScreen />}
      <TopRow onToggleDocumentation={toggleDocumentation} showDocumentation={showDocumentation}/>
      <Modal 
        isOpen={isModalOpen} 
        message={modalMessage} 
        link={modalLink} 
        onClose={() => setIsModalOpen(false)} 
      />
      {isLoading && (
        <div className="loading-container">
          <div className="spinner"></div>
        </div>
      )}
      {!imageUrl && (
        <div>
            <form onSubmit={handleSubmit} className="upload-form">
              <div className="same-line-div">
                <div className="input-group">
                  <label>
                    Upload mmCIF/PDB file: 
                    <input type="file" onChange={handleChange} />
                  </label>
                </div>
                <div className="input-group"> {/* Wrap the model selection in an input-group div */}
                  <label>
                    Model Selection:
                    <select 
                      className="options-dropdown"
                      value={model}
                      onChange={(e) => setModel(e.target.value)}>
                      <option value="0">DeepPBS (both readout)</option>
                      <option value="1">DeepPBS (shape readout)</option>
                      <option value="2">DeepPBS (groove readout)</option>
                      <option value="3">DeepPBS (with DNASeqInfo)</option>
                    </select>
                  </label>
                </div>
                <div className="input-group">
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      checked={calcRI}
                      onChange={handleCheckboxChange}
                    />
                    Calculate heavy atom relative importance (RI) scores
                  </label>
                </div>
                <div className='input-group'>
                <button type="submit">Run DeepPBS</button>
                </div>
              </div>
            </form>
          {isExample && !showDocumentation && (
            <div id="post-job">
              <div className="warning-text">Before uploading, please ensure your structure has protein and one contiguous DNA helix.</div>
              <br/>
              <div className="header-container">
              <h2>Geometric deep learning based prediction of protein-DNA binding specificity</h2>
              </div>
          <div className="content-container">
            <div className="viewer-section">
              <h3 className="section-header">Structure viewer</h3>
              <div className="vert-container">
                <NGLViewer timeString={timeString} baseUrl={baseUrl} isExample={isExample}/>
                <div> <strong>Rotate:</strong> drag + left click &nbsp; <strong>Translate:</strong> drag + right click &nbsp; <strong>Zoom:</strong> scroll </div>
              </div>
            </div>
            <div className="image-section">
              <h3 className="section-header">Position weight matrix</h3>
              <div className="vert-container">
                <img src='./o43680.svg' alt="Position Weight Matrix" className="responsive-img" />
              </div>
            </div>
          </div>
        </div>
          )}
      </div>
      )}

      {/* When Job Not Complete */}
      {jobNotComplete && !showDocumentation && (
         <div id="post-job">
         <div className="header-container">
           <h2>{title}</h2>
           <h3>Model: {modelText}</h3>
           <p className="ip-message">{ipMsg}</p>
         </div>
         <div className="log-container">
           <div className="log-header">Log:</div>
           <div className="logtext">{logtext}</div>
         </div>
       </div>
      )}

      {/* Output */}
      {imageUrl && !showDocumentation && (
        <div id="post-job">
          <div className="header-container">
            <h2>{title}</h2>
            <h3>Model: {modelText}</h3>
          </div>
          <div className="controls">
            <button onClick={() => downloadFile(zipUrl)}>Download DeepPBS Output</button>
          </div>
          <div className="content-container">
            <div className="viewer-section">
              <h3 className="section-header">Structure Viewer</h3>
              <div className="vert-container">
                <NGLViewer timeString={timeString} baseUrl={baseUrl}/>
                <div> <strong>Rotate:</strong> drag + left click &nbsp; <strong>Translate:</strong> drag + right click &nbsp; <strong>Zoom:</strong> scroll </div>
              </div>
            </div>
            <div className="image-column">
              <div className="image-section">
                <h3 className="section-header">Position Weight Matrix</h3>
                <div className="vert-container">
                <img src={imageUrl} alt="Position Weight Matrix" className={pymolUrl ? "responsive-small-img" : "responsive-img"} />
                </div>
              </div>
              {pymolUrl && (
                <div className="image-section">
                  <h3 className="section-header">Heavy-atom relative importance</h3>
                  <div className="vert-container">
                    <img id="heavy-img" onClick={() => downloadFile(pymolPseUrl)} src={pymolUrl} alt="Perturbation Image" className="responsive-small-img" />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {showDocumentation && (<Faq />
      )}
      <ImageRow />
      <footer className="app-footer">
      <p>The DeepPBS Web Server is maintained by <a href="https://www.rohslab.org/">The Rohs Lab</a> @ University of Southern California.</p>
    </footer>
    </div>
  );
}

export default () => (
    <Router>
      <Routes>
        <Route path="/*" element={<App />} />
      </Routes>
    </Router>
);

