import React, { useEffect, useState, useRef } from 'react';

const NGLViewer = ({ timeString, baseUrl, isExample }) => {
  const [viewportSize, setViewportSize] = useState({
    width: window.innerWidth * 0.4,
    height: window.innerHeight * 0.6
  });
  const viewportRef = useRef(null);
  const stageRef = useRef(null);

  // Function to fetch the filename from the server using the uniqueString
  const fetchFilename = async () => {
    try {
      const response = await fetch(`${baseUrl}/media/uploads/${timeString}.txt`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const filename = await response.text();
      return filename.trim(); // Ensure to trim any whitespace
    } catch (error) {
      console.error("Failed to fetch the filename:", error);
    }
  };



  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      setViewportSize({
        width: window.innerWidth * 0.4,
        height: window.innerHeight * 0.6
      });
    };

    // Initial resize to set dimensions
    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Initialize NGL Stage once and store it in a ref, then load file using fetched filename
  useEffect(() => {
    const initializeStage = async () => {
      if (viewportRef.current && !stageRef.current && window.NGL) {
        stageRef.current = new window.NGL.Stage("viewport", { backgroundColor: "#FFFFFF" });

        // Fetch the filename using the uniqueString, then load the file into NGL Stage
        if(isExample){
          console.log("in here bb");
          stageRef.current.loadFile(`${baseUrl}/media/O43680.pdb`, { defaultRepresentation: true });
        } else{
          const filename = await fetchFilename();
          if (filename) {
            stageRef.current.loadFile(`${baseUrl}/media/uploads/${filename}`, { defaultRepresentation: true });
          }
        }
      }
    };

    initializeStage();
  }, [timeString]); // Re-run this effect if uniqueString changes

  return <div id="viewport" ref={viewportRef} style={{ width: `${viewportSize.width}px`, height: `${viewportSize.height}px` }}></div>;
};

export default NGLViewer;
