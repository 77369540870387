import React from 'react';
import gh from'./gh.png';
import co from'./codeocean.svg';
import cite from'./cite.jpg';


const ImageRow = () => {
  // Array of image details
  const images = [
    { src: gh, alt: 'GitHub', link: 'https://github.com/timkartar/DeepPBS' },
    { src: co, alt: 'Code Ocean', link: 'https://doi.org/10.24433/CO.0545023.v2' },
    { src: cite, alt: 'DOI', link: 'https://doi.org/10.1038/s41592-024-02372-w' },
  ];

  // Handler for image click, redirecting to the specified URL
  const handleImageClick = (url) => {
    window.location.href = url;
  };

  return (
    <div className="image-row">
      {images.map((image, index) => (
        <img
          key={index}
          src={image.src}
          alt={image.alt}
          className="image"
          onClick={() => handleImageClick(image.link)}
        />
      ))}
    </div>
  );
};

export default ImageRow;