// Modal.js
import React from 'react';
import './Modal.css'; // Path to your CSS file

const Modal = ({ isOpen, message, link, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <span className="close-btn" onClick={onClose}>&times;</span>
        <p>{message}</p>
        <a href={link} target="_blank" rel="noopener noreferrer">{link}</a>
      </div>
    </div>
  );
};

export default Modal;
